import { Box, Center, SimpleGrid, Text } from "@chakra-ui/react";
import {
  IoPeopleOutline,
  IoTimeOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../loader";
import _ from "lodash";
import { useEffect } from "react";
import { updateByDateTickets, updateIsByDateTicketsReport } from "../../../../store/actions/realtimeAction";
import dayjs from "dayjs";
import { TICKET_STATUS } from "../../../../store/constants";
import StatsCard from "../../../StatsCard";
import { formatTime, generateHoursArray, formatNumber } from "../../../../store/helpers";
import CustomerCard from "../../../CustomerCard";
const BranchTicketsByDate = ({ branches }) => {
  const dispatch = useDispatch();
  const { byDateTickets, isFetchingByDateTickets, selectedTicketsStatus } =
    useSelector((state) => state.realtimeReducer);
  const { services, users, dateRange, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const { iamRoles } = useSelector((state) => state.permissionsReducer);
  const agentRoleId = iamRoles.find((a) => a.name === "agent")?.id;

  let agents = _.cloneDeep(users).filter(
    (a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId
  );

  const servicesClone = _.cloneDeep(services);
  const byDateTicketsClone = _.cloneDeep(byDateTickets).filter(
    (a) =>
      !selectedTicketsStatus.length ||
      (selectedTicketsStatus.length &&
        Number(a.ticket.status) === Number(selectedTicketsStatus))
  );

  //already filtered by branchId
  const data = byDateTicketsClone
    .map(({ ticket, customer }) => {
      const agent = agents.find((a) => a.id === ticket?.manage?.userId);
      const agentFullName = agent
        ? `${agent?.name} ${agent?.lastname}`
        : "Unnamed Agent";

      const waitingTime =
        Number(ticket.status) < 1
          ? dayjs().unix() - ticket.created
          : ticket.sessionStart - ticket.created;

      const serviceName = servicesClone.find(
        (a) => a.id === ticket.serviceId
      )?.name;

      let totalTimeInBranch = null;
      if (ticket.status === TICKET_STATUS.COMPLETED) {
        totalTimeInBranch =
          Math.abs(
            dayjs
              .unix(ticket.sessionStart)
              .diff(dayjs.unix(ticket.created), "seconds")
          ) + ticket.sessionInSeconds;
      } else if (
        ticket.status === TICKET_STATUS.ACTIVE ||
        ticket.status === TICKET_STATUS.IN_QUEUE //for previous days, this is abondoned
      ) {
        //not deterministic because we dont know when they abondoned the queue
        totalTimeInBranch = null;
      }

      return {
        customer,
        waitingTime,
        serviceName,
        agentFullName,
        ticket,
        totalTimeInBranch,
      };
    })
    .sort((a, b) => b.ticket.status - a.ticket.status);

  const startTimeStamp = dateRange[0];
  const endTimeStamp = dateRange[1];
  const chart = generateHoursArray(startTimeStamp, endTimeStamp).map((hour) => {
    const hourTickets =
      byDateTicketsClone.filter(
        (a) => dayjs.unix(a.ticket.created).format("h A") === hour
      )?.length || 0;
    return {
      hour,
      hourTickets,
    };
  });

  const maxTicketsPerHour = Math.max(...chart.map((a) => a.hourTickets));
  const peakHour =
    chart.find((a) => a.hourTickets === maxTicketsPerHour)?.hour || "NA";

  const numCompleted =
    data.filter((a) => Number(a.ticket.status) === TICKET_STATUS.COMPLETED)
      ?.length || 0;
  const numUnattended =
    data.filter((a) => Number(a.ticket.status) < 2)?.length || 0;
  const numNoShow =
    data.filter((a) => Number(a.ticket.status) === TICKET_STATUS.NO_SHOW)
      ?.length || 0;
  const numTickets = data.length;
  const maxTimeInBranch = Math.max(...data.map((a) => a.totalTimeInBranch));
  const customerWithMostTimeInBranch = data.find(
    (a) => a.totalTimeInBranch === maxTimeInBranch
  );

  const ticketLetter = customerWithMostTimeInBranch?.ticket?.ticketLetter;
  const ticketNumber = customerWithMostTimeInBranch?.ticket?.ticketNumber;
  const ticketNumOfLongestQueueingCustomer = ticketNumber
    ? `${ticketLetter}-${ticketNumber}`
    : "NA";
  const averageTotalTimeInBranch =
    data
      .filter((a) => a.totalTimeInBranch !== null)
      .reduce((acc, curr) => {
        return acc + curr.totalTimeInBranch;
      }, 0) / data.filter((a) => a.totalTimeInBranch !== null).length;

  useEffect(() => {
    return () => {
      dispatch(updateIsByDateTicketsReport(false));
    };
  }, []);

  const stats = [
    {
      title: "Total Tickets",
      stat: numTickets,
      icon: IoPeopleOutline,
      iconColor: "var(--green)",
    },
    {
      title: "Completed",
      stat: numCompleted,
      icon: IoCheckmarkCircleOutline,
      iconColor: "var(--orange)",
    },

    {
      title: "Unattended Tickets",
      stat: numUnattended,
      icon: IoCloseCircleOutline,
      iconColor: "var(--red)",
    },
    {
      title: "No Show",
      stat: numNoShow,
      icon: IoCloseCircleOutline,
      iconColor: "var(--red)",
    },
    {
      title: "Average Total Time In Branch",
      stat:
        !Number.isNaN(averageTotalTimeInBranch) &&
        Number.isFinite(averageTotalTimeInBranch)
          ? formatTime(averageTotalTimeInBranch)
          : "NA",
      icon: IoTimeOutline,
      iconColor: "var(--red)",
    },

    {
      title: "Customer with Longest Branch Visit Duration",
      stat: !Number.isNaN(maxTimeInBranch)
        ? formatTime(maxTimeInBranch) +
          " | " +
          ticketNumOfLongestQueueingCustomer
        : "NA",
      icon: IoPeopleOutline,
      iconColor: "var(--green)",
    },

    {
      title: "Peak Arrival Hour",
      stat: peakHour,
      icon: IoTimeOutline,
      iconColor: "var(--red)",
    },

    {
      title: "Maximum Visitors Per Hour",
      stat:
        Number.isFinite(maxTicketsPerHour) && !Number.isNaN(maxTicketsPerHour)
          ? formatNumber(maxTicketsPerHour)
          : "NA",
      icon: IoTimeOutline,
      iconColor: "var(--red)",
    },
  ];

  useEffect(()=>{
    dispatch(updateByDateTickets([]));
  }, [branchId])

  return (
    <Box>
      {isFetchingByDateTickets ? (
        <Loader />
      ) : (
        <Box>
          {_.isEmpty(byDateTickets) ? (
            <Center>
              <Box
                outline="1px dotted"
                mt={10}
                outlineColor="gray.300"
                p={4}
                h="70px"
                borderRadius={10}
                w={{ base: "90%", md: "50%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Center>
                  <Text color="gray.500">Search for tickets by date</Text>
                </Center>
              </Box>
            </Center>
          ) : (
            <Box>
              <Box mt={4}>
                <SimpleGrid
                  columns={{ base: 1, md: 4 }}
                  spacing={0}
                  mb={"10px"}
                >
                  {stats.map((a, i) => (
                    <Box
                      key={i}
                      px="20px"
                      py="15px"
                      mb="5px"
                      border="1px solid var(--borderGrey)"
                      borderRight={{
                        base: "1px solid #E5E4E5",
                        md: [3, 7].includes(i) ? "1px solid #E5E4E5" : "none",
                      }}
                      borderRadius={{
                        base: "10px",
                        md: [0, 4].includes(i)
                          ? "10px 0 0 10px"
                          : [3, 7].includes(i)
                          ? "0 10px 10px 0"
                          : "0",
                      }}
                    >
                      <StatsCard
                        title={a.title}
                        stat={a.stat}
                        icon={a.icon}
                        iconColor={a.iconColor}
                      />
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
              {data?.map((datum, i) => {
                return <CustomerCard key={datum.ticket.id} {...datum} />;
              })}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BranchTicketsByDate;
