import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

//   actions

import { resetDrawer } from "../../../../store/actions/miscAction";
// components
import SubscriptionForm from "../Form/SubscriptionForm";
import { resetForm } from "../../../../store/actions/subscriptionsActions";
import ReportForm from "../Form/ReportForm";
import PastReportsForm from "../Form/PastReportsForm";

function DrawerComponent(props) {
  const dispatch = useDispatch();

  const { drawer } = useSelector((state) => state.miscReducer);
  const { title, content, isOpen } = drawer;

  const onClose = () => {
    dispatch(resetDrawer());
    dispatch(resetForm());
  };

  const Components = {
    SubscriptionForm: <SubscriptionForm id={content.id} />,
    ReportForm: <ReportForm />,
    PastReportsForm: <PastReportsForm />,
  };

  return (
    <Drawer onClose={onClose} isOpen={Boolean(isOpen)} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader mt="50px">
          <Text fontSize="24px" fontWeight={400} color="var(--black)">
            {title}
          </Text>
        </DrawerHeader>
        <DrawerBody overflowY="auto" maxH="calc(100vh - 200px)" css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#a1a1a1',
          },
        }}>{Components[content.component]}</DrawerBody>

        <DrawerFooter>
          <HStack justifyContent="center" width="full">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerComponent;
