import React from "react";
import { Box, Heading, Text, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";

import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";

import FilterForm from "../../components/FilterForm";
import ServiceTimePerService from "../../components/organization/reports/ServiceTimePerService";
import _ from "lodash";
import {
  getReportDescription,
  getReportRangeString,
} from "../../store/helpers";
import { Helmet } from "react-helmet";
import DownloadReport from "../../components/DownloadReport";
import SelectBranchInfo from "../../components/misc/SelectBranchInfo";

const ServiceTimePerServicePage = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_service_time_per_service
      : REPORT_TYPE_IDS.monthly_organization_service_time_per_service;

  const reportTitle = ` Service Time Per Service`;
  console.log(reportTitle);

  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>
            {getReportRangeString(reportRange)} Organization Service Time Per
            Service
          </title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <HStack flexDir={"row"} justifyContent={"space-between"}>
          <Box>
            <Heading fontSize="34px" color="#222" fontWeight="700">
              {reportTitle}

              <Tooltip
                label={getReportDescription(reportRange).ServiceTimePerService}
                aria-label="A tooltip"
              >
                <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
              </Tooltip>
            </Heading>
          </Box>
          <DownloadReport
            reportRange={reportRange}
            reportTypeId={reportTypeId}
          />
        </HStack>
      ) : null}
      {!_.isEmpty(data) ? <ServiceTimePerService data={data} /> : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
    </Box>
  );
};

export default ServiceTimePerServicePage;
