import { Box, Heading, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import FilterForm from "../../components/FilterForm";
import TicketsByBranchReport from "../../components/organization/reports/TicketsByBranch";
import Loader from "../../components/loader";
import {
  getReportDescription,
  getReportRangeString,
} from "../../store/helpers";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";
import { Helmet } from "react-helmet";
import DownloadReport from "../../components/DownloadReport";
import SelectBranchInfo from "../../components/misc/SelectBranchInfo";

const TicketsPerBranch = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_tickets_per_branch
      : REPORT_TYPE_IDS.monthly_organization_tickets_per_branch;

  const title = ` Tickets Per Branch`;

  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>
            {getReportRangeString(reportRange)} Organization Tickets Per Branch
          </title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <HStack flexDirection={"row"} justifyContent={"space-between"}>
                <Heading fontSize="34px" color="#222" fontWeight="700">
                  {title}

                  <Tooltip
                    label={getReportDescription(reportRange).TicketsByBranch}
                    aria-label="A tooltip"
                  >
                    <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                  </Tooltip>
                </Heading>
                <DownloadReport
                  reportRange={reportRange}
                  reportTypeId={reportTypeId}
                />
              </HStack>

              <TicketsByBranchReport data={data} />
            </>
          ) : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
        </>
      )}
    </Box>
  );
};

export default TicketsPerBranch;
