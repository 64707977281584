import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Text, Badge, HStack, VStack, Icon } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RiExpandUpDownLine, RiContractUpDownLine } from "react-icons/ri";
import { formatTime, capitalizeName } from "../store/helpers";
import { TICKET_STATUS } from "../store/constants";

function CustomerCard({
  customer,
  agentFullName,
  ticket,
  waitingTime,
  serviceName,
  totalTimeInBranch,
  expandedAll,
}) {
  // Add the 'customer' prop to the props validation
  CustomerCard.propTypes = {
    customer: PropTypes.shape({
      fullname: PropTypes.string.isRequired,
      mobileNumber: PropTypes.string.isRequired,
    }).isRequired,
    agentFullName: PropTypes.string.isRequired,
    ticket: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ticketId: PropTypes.string.isRequired,
      ticketLetter: PropTypes.string.isRequired,
      ticketNumber: PropTypes.number.isRequired,
      created: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      sessionInSeconds: PropTypes.number.isRequired,
      sessionStart: PropTypes.number.isRequired,
    }).isRequired,
    waitingTime: PropTypes.number.isRequired,
    serviceName: PropTypes.string.isRequired,
    totalTimeInBranch: PropTypes.number.isRequired,
    expandedAll: PropTypes.bool.isRequired,
  };

  const { isByDateTicketsReport } = useSelector(
    (state) => state.realtimeReducer
  );
  const [isExpanded, setIsExpanded] = useState(expandedAll);
  const {
    id: ticketId,
    ticketLetter,
    ticketNumber,
    created,
    status,
    sessionInSeconds,
    sessionStart,
  } = ticket;

  const statusString = {
    0: isByDateTicketsReport ? "Abondoned" : "In Queue",
    1: isByDateTicketsReport ? "Abondoned" : "Active",
    2: "No Show",
    3: "Completed",
  }[Number(status)];

  const StatusColor = {
    0: "gray",
    1: "blue-classic",
    2: "red",
    3: "green",
  }[Number(status)];
  let serviceTime = status === TICKET_STATUS.COMPLETED ? sessionInSeconds : 0;

  serviceTime =
    status === TICKET_STATUS.ACTIVE
      ? dayjs().diff(dayjs.unix(sessionStart), "seconds")
      : serviceTime;

  let fullname = null;
  let mobileNumber = null;
  let mdcNumber = null;
  if (!_.isEmpty(customer)) {
    fullname = customer.fullname;
    mobileNumber = customer.mobileNumber;
    fullname = capitalizeName(fullname);
    mdcNumber = customer?.mdcNumber;
  }

  // update isExpanded state when expandedAll changes
  useEffect(() => {
    setIsExpanded(expandedAll);
  }, [expandedAll]);

  return (
    <Box
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden"
      border="1px solid var(--borderGrey)"
      mt={3}
    >
      <VStack align="start" spacing={2}>
        <Box
          p="15px 20px"
          borderBottom={isExpanded ? "1px solid var(--borderGrey)" : "none"}
          w="100%"
        >
          <HStack justifyContent="space-between">
            <HStack spacing="20px">
              <Text
                fontSize="16px"
                fontWeight={500}
                whiteSpace="nowrap"
              >{`${ticketLetter}-${ticketNumber}`}</Text>
              <Badge bg={`var(--${StatusColor})`} color="white">
                {statusString}
              </Badge>
              <Text
                fontSize="16px"
                fontWeight={400}
                color="var(--gray-8)"
                isTruncated
                maxW="250px"
              >
                {serviceName}
              </Text>
            </HStack>
            <Icon
              as={isExpanded ? RiContractUpDownLine : RiExpandUpDownLine}
              onClick={() => setIsExpanded(!isExpanded)}
              boxSize="24px"
              color="var(--gray-8)"
              cursor="pointer"
            />
          </HStack>
        </Box>

        <VStack
          align="start"
          spacing="10px"
          px="20px"
          display={isExpanded ? "block" : "none"}
        >
          <HStack>
            <Text fontSize="14px" w="110px" fontWeight={500}>
              Ticket ID:
            </Text>
            <Text fontSize="14px" fontWeight={400} color="var(--black-dry)">
              {ticketId}
            </Text>
          </HStack>
          {!_.isEmpty(customer) ? (
            <>
              <HStack>
                <Text fontSize="14px" w="110px" fontWeight={500}>
                  Customer Name:
                </Text>
                <Text fontSize="14px" fontWeight={400} color="var(--black-dry)">
                  {fullname}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="14px" w="110px" fontWeight={500}>
                  Mobile Number:
                </Text>
                <Text fontSize="14px" fontWeight={400} color="var(--black-dry)">
                  {mobileNumber}
                </Text>
              </HStack>
              {mdcNumber ? (
                <HStack>
                  <Text fontSize="14px" w="110px" fontWeight={500}>
                    MDC Number:
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight={400}
                    color="var(--black-dry)"
                  >
                    {mdcNumber}
                  </Text>
                </HStack>
              ) : null}
            </>
          ) : null}

          {status !== TICKET_STATUS.IN_QUEUE ? (
            <HStack>
              <Text fontSize="14px" w="110px" fontWeight={500}>
                Support Agent:
              </Text>
              <Text
                fontSize="14px"
                fontWeight={400}
                color="var(--blue-classic)"
              >
                {Number(status) >= 1 ? agentFullName : "NA"}
              </Text>
            </HStack>
          ) : null}
        </VStack>
        <VStack
          align="start"
          spacing="0px"
          p="10px 20px"
          borderTop="1px solid var(--borderGrey)"
          w="100%"
          bg="#fafafa"
        >
          <HStack spacing="30px">
            <Box>
              <HStack>
                <Text fontSize="14px" w="110px" fontWeight={500}>
                  Time Created:
                </Text>
                <Text fontSize="14px" fontWeight={400} color="var(--gray-8)">
                  {dayjs.unix(created).format("D MMM, YY HH:mm:ss")}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="14px" w="110px" fontWeight={500}>
                  Time in Branch:
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  color="var(--gray-8)"
                  id={`timeInBranch-${ticketId}`}
                >
                  {status === 3 || status <= 1
                    ? formatTime(totalTimeInBranch)
                    : "NA"}
                </Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontSize="14px" w="110px" fontWeight={500}>
                  Waiting Time:
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  color="var(--gray-8)"
                  id={`waitingTime-${ticketId}`}
                >
                  {isByDateTicketsReport && Number(status) < 2
                    ? "NA"
                    : formatTime(waitingTime)}
                </Text>
              </HStack>
              {status === 3 || (status === 1 && !isByDateTicketsReport) ? (
                <HStack>
                  <Text fontSize="14px" w="110px" fontWeight={500}>
                    Service Time:
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight={400}
                    color="var(--gray-8)"
                    id={`serviceTime-${ticketId}`}
                  >
                    {formatTime(serviceTime)}
                  </Text>
                </HStack>
              ) : null}
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}

export default CustomerCard;
