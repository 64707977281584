import React, { useEffect, useState } from "react";
import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import _ from "lodash";

import { REPORT_TYPE_IDS } from "../../../store/constants";

import FilterForm from "../../../components/FilterForm";
import BranchTicketsPerService from "../../../components/branches/reports/daily/BranchTicketsPerService";
import { getReportDescription } from "../../../store/helpers";
import { Helmet } from "react-helmet";
import SelectBranchInfo from "../../../components/misc/SelectBranchInfo";

const DailyBranchTicketsPerService = ({
  showFilterForm = true,
  
  showTitle = 1,
}) => {
  const { reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_branch_tickets_per_service;

  const reportTitle = `Tickets Per Service`;
  console.log(reportTitle);

    const reportsDataClone = _.cloneDeep(reportsData);
    let reports = reportsDataClone.filter(
      (a) => a.reportTypeId === reportTypeId
    );
    if (branchId.length > 0) {
      reports = reports.filter((a) => a.branchId === branchId);
    }
    const data = reports.map((a) => a.data) || []

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Branch Tickets Per Service</title>
        </Helmet>
      )}

      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <Box mb="20px">
          <Heading fontSize="34px" color="#222" fontWeight="700">
            {reportTitle}

            <Tooltip
              label={
                getReportDescription(reportRange).TicketsPerServiceBranches
              }
              aria-label="A tooltip"
            >
              <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
            </Tooltip>
          </Heading>
        </Box>
      ) : null}
      {!_.isEmpty(data)
        ? data.map((a, index) => (
            <BranchTicketsPerService
              key={index}
              branchId={a.branchId}
              branchIndex={index + 1}
              data={a.serviceData}
              numServices={a.numServices}
              branchName={a.branchName}
            />
          ))
        : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
    </Box>
  );
};

export default DailyBranchTicketsPerService;
