import dayjs from "dayjs";
import { fetchData } from "./defaultAction";
import { ACTION_TYPES } from "./types";
import { toast } from "react-toastify";
import io from "socket.io-client";
import _ from "lodash";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
export const listenToEvent =
  (eventName, updateType, organizationId) => (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });

    socket[eventName].on(`${eventName}-updated`, (res) => {
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      // console.log(type, payload);
      dispatch({
        type,
        payload,
      });

      if (!organizationId.length > 0) {
        dispatch({
          type,
          payload,
        });
      }
    });
  };

export const listenTickets = ({ organizationId }) =>
  listenToEvent("management_tickets", "LISTEN_TICKETS", organizationId);

export const listenCallTickets = ({ organizationId }) =>
  listenToEvent(
    "management_callTickets",
    "LISTEN_CALL_TICKETS",
    organizationId
  );

export const listenTicketTransfers = ({ organizationId }) =>
  listenToEvent(
    "management_transferTickets",
    "LISTEN_TICKET_TRANSFERS",
    organizationId
  );

export const listenAgentSessions = ({ organizationId }) =>
  listenToEvent("management_sessions", "LISTEN_AGENT_SESSIONS", organizationId);

//RT_TICKETS: get tickets from midnight to now

export const listenToEvents =
  ({ organizationId }) =>
  async (dispatch) => {
    //listeners
    dispatch(getTickets({ organizationId }));
    dispatch(getTicketTransfers({ organizationId }));
    dispatch(getAgentSessions({ organizationId }));
    dispatch(getCustomers({ organizationId }));

    dispatch(listenTickets({ organizationId }));
    dispatch(listenCallTickets({ organizationId }));
    dispatch(listenTicketTransfers({ organizationId }));
    dispatch(listenAgentSessions({ organizationId }));
  };

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
};

export const stopListeners = () => (dispatch) => {
  dispatch(listenToEventStop("management_tickets", "LISTEN_TICKETS"));
  dispatch(listenToEventStop("management_callTickets", "LISTEN_CALL_TICKETS"));
  dispatch(
    listenToEventStop("management_transferTickets, LISTEN_TICKET_TRANSFERS")
  );
  dispatch(listenToEventStop("management_sessions", "LISTEN_AGENT_SESSIONS"));
};

// get tickets
export const getTickets =
  ({ organizationId }) =>
  async (dispatch) => {
    const endpoint = "/management/tickets";
    const payload = {
      createdFrom: dayjs().startOf("day").unix(),
      createdTo: dayjs().unix(),
      organizationId,
    };
    const actionType = ACTION_TYPES.RT_TICKETS;
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

// get tickets
export const getCallTickets =
  ({ organizationId }) =>
  async (dispatch) => {
    const endpoint = "/management/callTickets";
    const payload = {
      createdFrom: dayjs().startOf("day").unix(),
      createdTo: dayjs().unix(),
      organizationId,
    };
    const actionType = ACTION_TYPES.CALL_TICKETS;
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

//Update tickets

export const updateActiveTickets = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_ACTIVE_TICKETS,
    payload,
  });
};

export const updateAgentSessions = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_AGENT_SESSIONS,
    payload,
  });
};

//SET_TICKET_TRANSFERS
export const getTicketTransfers =
  ({ organizationId }) =>
  async (dispatch) => {
    const endpoint = "/management/transferTickets";
    const payload = {
      createdFrom: dayjs().startOf("day").unix(),
      createdTo: dayjs().unix(),
      organizationId,
    };
    const actionType = ACTION_TYPES.GET_TICKET_TRANSFERS;
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

//GET_AGENT_SESSIONS
export const getAgentSessions =
  ({ organizationId }) =>
  async (dispatch) => {
    const endpoint = "/management/sessions";
    const payload = { organizationId };
    const actionType = ACTION_TYPES.GET_AGENT_SESSIONS;
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

//GET_CUSTOMERS
export const getCustomers =
  ({ organizationId }) =>
  async (dispatch) => {
    const endpoint = "/management/customers";
    const payload = {
      updatedFrom: dayjs().startOf("day").unix(),
      updatedTo: dayjs().unix(),
      organizationId,
    };
    const actionType = ACTION_TYPES.GET_CUSTOMERS;
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

//TICKET_LOOKUP
export const ticketLookup =
  (ticketNumber, dateRange, organizationId, branchId) => async (dispatch) => {
    if (!dateRange) {
      toast.error("Please select a date range");
      return;
    }
    if (!branchId) {
      toast.error("Please select a branch");
      return;
    }
    const [createdFrom, createdTo] = dateRange;
    if (!createdFrom || !createdTo) {
      toast.error("Please select a valid date range");
      return;
    }

    const [letter, num] = ticketNumber.split("-");

    if (!/^[a-zA-Z]+$/.test(letter)) {
      toast.error("Invalid ticket number");
      return;
    }

    if (Number.isNaN(num) || num.length === 0) {
      toast.error("Invalid ticket number");
      return;
    }
    const endpoint = `/management/tickets/by-number`;
    const created = dayjs.unix(createdFrom).add(6, "hour").unix();
    const payload = {
      ticketNumber: num,
      ticketLetter: letter,
      created,
      organizationId,
      branchId,
    };
    const actionType = ACTION_TYPES.CUSTOMER_TICKET_LOOKUP;
    const singleResponse = 1;
    dispatch(updateFetchingTicket(true));
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch(updateFetchingTicket(false));
  };

//UPDATE_FETCHING_TICKET
export const updateFetchingTicket = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FETCHING_TICKET,
    payload,
  });
};

//UPDATE_FETCHING_BY_DATE_TICKETS
export const updateIsFetchingByDateTickets = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_IS_FETCHING_BY_DATE_TICKETS,
    payload,
  });
};

//UPDATE_IS_BY_DATE_TICKETS_REPORT
export const updateIsByDateTicketsReport = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_IS_BY_DATE_TICKETS_REPORT,
    payload,
  });
};

// get tickets
export const getTicketsByDate =
  ({ organizationId, branchId, created }) =>
  async (dispatch) => {
    dispatch(updateIsByDateTicketsReport(true));
    if (_.isEmpty(branchId)) {
      toast.error("Please select a branch");
      return;
    }
    const endpoint = "/management/tickets/by-date";
    const payload = {
      organizationId,
      branchId,
      created,
    };
    const actionType = ACTION_TYPES.BY_DATE_TICKETS;
    const singleResponse = 0;
    dispatch(updateIsFetchingByDateTickets(true));
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch(updateIsFetchingByDateTickets(false));
  };

// UPDATE_SELECTED_TICKETS_STATUS

export const updateSelectedTicketsStatus = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SELECTED_TICKETS_STATUS,
    payload,
  });
};

// UPDATE_TIME_NOW
export const updateTimeNow = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_TIME_NOW,
    payload: dayjs().unix(),
  });
};

export const updateByDateTickets = (byDateTickets) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.BY_DATE_TICKETS,
    payload: byDateTickets,
  });
};
