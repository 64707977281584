import React, { useEffect } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  downloadReport,
  updateDownloadingReport,
} from "../store/actions/subscriptionsActions";

const DownloadReport = ({ reportRange, reportTypeId }) => {
  const { organizationId, branchId, dateRange } = useSelector(
    (state) => state.defaultReducer
  );
  const { downloadingReport } = useSelector(
    (state) => state.subscriptionsReducer
  );
  const dispatch = useDispatch();
  const data = {
    organizationId,
    branchId,
    dateRange,
    reportTypeId,
    reportRange,
  };

  useEffect(() => {
    dispatch(updateDownloadingReport(false));
  }, []);

  return (
    <Box>
      <Button
        className="downloadButton"
        isLoading={downloadingReport}
        loadingText="Downloading..."
        size="md"
        onClick={() => {
          dispatch(downloadReport(data));
        }}
        leftIcon={<DownloadIcon />}
      >
        Download
      </Button>
    </Box>
  );
};

export default DownloadReport;
