import { Box, Heading, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import FilterForm from "../../components/FilterForm";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import {
  getReportDescription,
  getReportRangeString,
} from "../../store/helpers";
import ArrivalRatesPerDayReport from "../../components/branches/reports/ArrivalRatesPerDay";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";
import _ from "lodash";
import { Helmet } from "react-helmet";
import DownloadReport from "../../components/DownloadReport";
import SelectBranchInfo from "../../components/misc/SelectBranchInfo";

const ArrivalRatesPerDay = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_branch_arrival_rates_per_day
      : REPORT_TYPE_IDS.monthly_branch_arrival_rates_per_day;

  const title = `Branch Arrival Rates Per Day`;

  const reportsDataClone = _.cloneDeep(reportsData);
  let reports = reportsDataClone.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId.length > 0) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data) || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>
            {getReportRangeString(reportRange)} Branch Arrival Rates Per Day
          </title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <HStack flexDirection={"row"} justifyContent={"space-between"}>
                <Heading fontSize="34px" color="#222" fontWeight="700">
                  {title}

                  <Tooltip
                    label={
                      getReportDescription(reportRange)
                        .BranchesArrivalRatesPerDay
                    }
                    aria-label="A tooltip"
                  >
                    <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                  </Tooltip>
                </Heading>

                <DownloadReport
                  reportRange={reportRange}
                  reportTypeId={reportTypeId}
                />
              </HStack>

              <ArrivalRatesPerDayReport data={data} />
            </>
          ) : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerDay;
