import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import OrganizationTicketsPerBranch from "../../../components/organization/reports/daily/OrganizationTicketsPerBranch";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import { Helmet } from "react-helmet";
import SelectBranchInfo from "../../../components/misc/SelectBranchInfo";

const DailyOrganizationTicketsPerBranch = ({
  showFilterForm = true,
  
  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId = REPORT_TYPE_IDS.daily_organization_tickets_per_branch;

  const title = ` Tickets Per Branch`;
  

  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Organization Tickets Per Branch</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <Heading fontSize="34px" color="#222" fontWeight="700">
                {title}

                <Tooltip
                  label={getReportDescription(reportRange).TicketsByBranch}
                  aria-label="A tooltip"
                >
                  <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                </Tooltip>
              </Heading>

              <OrganizationTicketsPerBranch data={data} />
            </>
          ) : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
        </>
      )}
    </Box>
  );
};

export default DailyOrganizationTicketsPerBranch;
