import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import FilterForm from "../../../components/FilterForm";
import { useSelector } from "react-redux";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import Loader from "../../../components/loader";
import {
  getReportDescription,
  getReportRangeString,
} from "../../../store/helpers";
import BranchCustomersInBranchPerHourReport from "../../../components/branches/reports/daily/BranchNumberOfCustomersInHour";
import _ from "lodash";
import { Helmet } from "react-helmet";
import SelectBranchInfo from "../../../components/misc/SelectBranchInfo";

const BranchCustomersInBranchPerHour = ({
  showFilterForm = true,
  reportIndex = 1,

  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId = REPORT_TYPE_IDS.daily_branch_customers_in_branch_per_hour;

  const reportsDataClone = _.cloneDeep(reportsData);
  let reports = reportsDataClone.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId.length > 0) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data) || [];
  console.log(data)

  const title = `Customers In Branch Per Hour`;

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>
            {getReportRangeString(reportRange)} Branch Customers In Branch Per Hour
          </title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <Heading fontSize="34px" color="#222" fontWeight="700">
                {title}

                <Tooltip
                  label={
                    getReportDescription(reportRange)
                      .BranchesCustomersInBranchPerHour
                  }
                  aria-label="A tooltip"
                >
                  <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                </Tooltip>
              </Heading>
              <BranchCustomersInBranchPerHourReport data={data} reportIndex={reportIndex}/>
            </>
          ) : (
        <SelectBranchInfo message={'Update Filters and Hit Search to retrieve data'}/>
      )}
        </>
      )}
    </Box>
  );
};

export default BranchCustomersInBranchPerHour;
