import { Box, Heading, Icon, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";
import FilterForm from "../../../components/FilterForm";
import { InfoIcon } from "@chakra-ui/icons";
import ArrivalRatesPerHourReport from "../../../components/organization/reports/realtime/OrganizationArrivalRatesPerHour";
import { Helmet } from "react-helmet";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";

const OrganizationArrivalRatesPerHour = ({
  showFilterForm = false,
  reportIndex = 1,
}) => {
  const { reportRange, workDay } = useSelector((state) => state.defaultReducer);
  const reportTitle = `Arrival Rates Per Hour`;
  console.log(reportTitle);
  return (
    <Box>
      <Helmet>
        <title>{reportTitle}</title>
      </Helmet>
      {showFilterForm ? <FilterForm /> : <></>}
      <>
        <Heading fontSize="34px" color="#222" fontWeight="700">
          {reportTitle}
          <Tooltip
            label={
              getReportDescription(reportRange).OrganizationArrivalRatesPerHour
            }
            aria-label="A tooltip"
          >
            <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
          </Tooltip>
        </Heading>
        {_.isEmpty(workDay) ? (
          <Loader />
        ) : (
          <ArrivalRatesPerHourReport reportIndex={reportIndex} />
        )}
      </>
    </Box>
  );
};

export default OrganizationArrivalRatesPerHour;
