import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = "https://cx.api.averly.com.na";


// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const accessToken = Cookies.get("accessToken");
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

export default axiosInstance;
