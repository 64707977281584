import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import _ from "lodash";

import Select from "../../../../averly/Select";
import {
  setPastReportDateRange,
  updateSubscriptionId,
} from "../../../../../store/actions/subscriptionsActions";
import { REPORT_RANGES } from "../../../../../store/constants";
import dayjs from "dayjs";

const SubscriptionSelection = () => {
  const dispatch = useDispatch();
  const { subscriptions, subscriptionId } = useSelector(
    (store) => store.subscriptionsReducer
  );
  const subscriptionsClone = _.cloneDeep(subscriptions);
  const options = subscriptionsClone.map((a) => {
    return {
      name: a.subscriptionName,
      id: a.id,
    };
  });
  const handleOnchange = (subscriptionId) => {
    dispatch(updateSubscriptionId(subscriptionId));
    const subscriptionReportRange = subscriptionsClone.find(
      (a) => a.id === subscriptionId
    )?.reportRange;

    let dateRange = null;
    switch (subscriptionReportRange) {
      case REPORT_RANGES.DAY:
        // if its monday, set dateRange to Friday
        if (dayjs().day() === 1) {
          dateRange = [
            dayjs().subtract(3, "day").startOf("day").unix(),
            dayjs().subtract(3, "day").endOf("day").unix(),
          ];
        } else {
          dateRange = [
            dayjs().subtract(1, "day").startOf("day").unix(),
            dayjs().subtract(1, "day").endOf("day").unix(),
          ];
        }

        break;
      case REPORT_RANGES.WEEK:
        dateRange = [
          dayjs().subtract(1, "week").startOf("week").unix(),
          dayjs().subtract(1, "week").endOf("week").unix(),
        ];
        break;
      case REPORT_RANGES.MONTH:
        dateRange = [
          dayjs().subtract(1, "month").startOf("month").unix(),
          dayjs().subtract(1, "month").endOf("month").unix(),
        ];
        break;
    }

    dispatch(setPastReportDateRange(dateRange));
  };
  return (
    <Select
      label="Subscription Selection"
      placeholder="Select Subscription"
      value={subscriptionId}
      options={options}
      onChange={handleOnchange}
    />
  );
};

export default SubscriptionSelection;
