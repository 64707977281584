import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateBranchId } from "../../store/actions/defaultAction";
import { useEffect, useState } from "react";
import _ from "lodash";

import Select from "../averly/Select";

const BranchSelection = () => {
  const dispatch = useDispatch();
  const { branches, organizationId, branchId } = useSelector((store) => store.defaultReducer);
  const [branchesClone, setBranchesClones] = useState(_.cloneDeep(branches).filter((a) => a.organizationId === organizationId && !_.isEmpty(a.reportStatus) && Number(a.reportStatus)  ))

  useEffect(() => {
    const updatedBranches = _.cloneDeep(branches).filter((a) => a.organizationId === organizationId && !_.isEmpty(a.reportStatus) && Number(a.reportStatus))
    setBranchesClones(updatedBranches)
  }, [branches, organizationId]);

  const handleOnchange = (branchId) => {
    dispatch(updateBranchId({ branchId, organizationId }))
  }

  return (
    <Select
      label="Branch"
      placeholder="All Branches"
      value={branchId}
      options={branchesClone}
      onChange={handleOnchange} 
    />
  )
}

export default BranchSelection;
